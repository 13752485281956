export const mountLabeledFamilyCenterBusinessUnitStorageLocations = (storageLocations, labeledFamilyCenterId, inventoryStorageLocations, productionLineStorageLocation) => {
  if(storageLocations?.length == 0) return [];

  let data = [];

  for(const storage of storageLocations){
    const _inventory = inventoryStorageLocations?.find(x => x.StorageLocationId == storage.Id);

    const _data = {
      Id: _inventory?.LabeledFamilyCenterBusinessUnitStorageLocationId,
      LabeledFamilyCenterId: labeledFamilyCenterId,
      BusinessUnitStorageLocationId: storage.Id,
      CurrentMaximumStock: _inventory?.CurrentMaximumStock,
      CurrentMinimumStock: _inventory?.CurrentMinimumStock,
      CurrentRefuelingPoint: _inventory?.CurrentRefuelingPoint,
      CurrentRefuelingPointPercent: _inventory?.CurrentRefuelingPointPercent,
      LabeledFamilyCenterStorageLocationProductionLines: productionLineStorageLocation?.[storage.Id]?.map(y => (
        {
          Id: y?.VinculoId,
          LabeledFamilyCenterBusinessUnitStorageLocationId: y?.LabeledFamilyCenterBusinessUnitStorageLocationId,
          BusinessUnitProductionLineId: y.Id
        }
      ))
    }

    data = [...data, _data];
  }

  return data;
}

export const mapperInventoryStorageLocations = (storageLocations, data) => {
  let item = [];

  for(const storage of storageLocations){
    const _inventory = data?.find(x => x.BusinessUnitStorageLocationId == storage.Id);
    const _item = {
      StorageLocationId: storage.Id,
      Description: storage.Description,
      LabeledFamilyCenterBusinessUnitStorageLocationId: _inventory?.Id,
      CurrentMaximumStock: _inventory?.CurrentMaximumStock,
      CurrentMinimumStock: _inventory?.CurrentMinimumStock,
      CurrentRefuelingPoint: _inventory?.CurrentRefuelingPoint,
      CurrentRefuelingPointPercent: _inventory?.CurrentRefuelingPointPercent
    }

    item = [ ...item, _item ];
  }  

  return item;
}

export const mapperStorageLocationsProductionLines = (storageLocations, productionLinesSaved, productionLines) => {
  let item = {};

  for(const storage of storageLocations){
    const _productionLines = productionLinesSaved?.find(x => x.BusinessUnitStorageLocationId == storage.Id)?.LabeledFamilyCenterStorageLocationProductionLines;
    
    const _item = _productionLines?.map(x => ({
      ...x,
      Id: x.BusinessUnitProductionLineId,
      Name: productionLines.find(y => y.Id == x.BusinessUnitProductionLineId).ProductionLine,
      VinculoId: x.Id,
      StorageLocationId: storage.Id
    }))

    item = { 
      ...item, 
      [storage.Id] : _item 
    };
  }

  return item;
}