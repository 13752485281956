import { Component } from "react";
import PropTypes from "prop-types";
import Moment from "moment";
import { Row, Col } from "react-flexbox-grid";
import { Button } from 'components/uikit-adapter/index';
import IconLocked from "images/icn-padlock-locked.svg";
import IconUnlocked from "images/icn-padlock-unlocked.svg";
import "./deadline-control.css";
import DateInput from "../date-input";
import intl from 'react-intl-universal'
import { validateMaxDate, validateMinDate } from "./utils";

const isoDateFormat = "YYYY-MM-DD";

class DeadlineControl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blocked: true,
      dataEntrega: "",
      dataColeta: "",
    };

    this.changeValue = this.changeValue.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.obterIdentificador = this.obterIdentificador.bind(this);
  }

  componentDidMount() {
    const identificador = this.obterIdentificador();

    const blockedStorage = localStorage.getItem(identificador)
      ? localStorage.getItem(identificador)
      : "true";
    const blocked = blockedStorage === "true";

    const dataColeta = this.props.Coleta
      ? Moment(this.props.Coleta).format(isoDateFormat)
      : "";
    const dataEntrega = this.props.DataEntregaDe
      ? Moment(this.props.DataEntregaDe).format(isoDateFormat)
      : "";

    this.setState({
      blocked,
      dataEntrega,
      dataColeta,
      dataColetaDe: this.props.DataColetaDe,
      dataEntregaDe: this.props.DataEntregaDe,
    });
  }

  changeValue = (name, value) => {
    if (!value) {
      this.setState({ [name]: value })
      return;
    }

    const { blocked, dataColeta, dataEntrega } = this.state;
    const { ColetaDisabled, noLockOption } = this.props;

    let pickUpDate = dataColeta;
    let deliveryDate = dataEntrega;
    const data = value.split("-");
    const currentDate = new Date(
      parseInt(data[0]),
      parseInt(data[1]) - 1,
      parseInt(data[2])
    );

    if (name === "dataColeta") {
      pickUpDate = value;
      if (blocked && !noLockOption) {
        currentDate.setDate(currentDate.getDate() + this.props.TransitTime);
        deliveryDate = Moment(currentDate).format(isoDateFormat);
      }
    } else if (name === "dataEntrega") {
      deliveryDate = value;
      if (blocked && !ColetaDisabled && !noLockOption) {
        currentDate.setDate(currentDate.getDate() - this.props.TransitTime);
        pickUpDate = Moment(currentDate).format(isoDateFormat);
      }
    }

    this.setState(
      {
        dataColeta: pickUpDate,
        dataEntrega: deliveryDate,
      },
      () => this.props.OnChange(pickUpDate, deliveryDate)
    );
  };


  updateStatus() {
    const identificador = this.obterIdentificador();

    const blockedStorage = localStorage.getItem(identificador)
      ? localStorage.getItem(identificador)
      : "true";

    const blocked = blockedStorage === "false";
    localStorage.setItem(identificador, blocked);

    this.setState({
      blocked,
    });
  }

  obterIdentificador() {
    return this.props.Identificador !== undefined
      ? "blocked_deadlinecontrol-" + this.props.Identificador
      : "blocked_deadlinecontrol";
  }

  validateIconLocked = (blocked) => blocked ? IconLocked : IconUnlocked

  render() {
    const {
      Disabled,
      ColetaDisabled,
      EntregaDisabled,
      TituloColeta,
      TituloEntrega,
      CadeadoDisabled,
      noLockOption,
      showSeparator,
      noMinDate,
      noMaxDate,
      days
    } = this.props;

    const idPedido = this.props.Identificador.replace(/\D/g, '');
    const { dataColeta, dataEntrega, blocked } = this.state;
    const datasStorage = JSON.parse(localStorage.getItem(`editarPedidoEmNegocicacaoDatas_${idPedido}`));
    const dataEntregaDe = dataEntrega;
    const dataColetaDe = (datasStorage) ? datasStorage.DataColeta : dataColeta;

    return (
      <Row id='row-deadline-control'>
        <Col className="nomargin nopaddinright columnsSize">
          <DateInput
            label={TituloColeta}
            value={dataColetaDe}
            onChange={(date) => this.changeValue("dataColeta", date)}
            disabled={Disabled || ColetaDisabled}
            minDate={days ? validateMinDate(noMinDate, days, isoDateFormat) : null}
            maxDate={days ? validateMaxDate(noMaxDate, days, isoDateFormat) : null}
            className="data-coleta-picker"
            data-testid='deadLineControlColeta'
          />
        </Col>
        <Col className="buttomdeadline">
          {
            !noLockOption ?
              <Button
                width="40px"
                type="secondary"
                icon={this.validateIconLocked(blocked)}
                onClick={() => this.updateStatus()}
                disabled={Disabled || CadeadoDisabled}
              />
              :
              <div className='date-separator-text'>
                {
                  showSeparator &&
                  <h5>
                    {intl.get('stocks.until')}
                  </h5>
                }
              </div>
          }
        </Col>
        <Col className="nomargin nopaddinleft columnsSize">
          <DateInput
            label={TituloEntrega}
            value={dataEntregaDe}
            onChange={(date) => this.changeValue("dataEntrega", date)}
            disabled={Disabled || EntregaDisabled}
            minDate={days ? validateMinDate(noMinDate, days, isoDateFormat) : null}
            maxDate={days ? validateMaxDate(noMaxDate, days, isoDateFormat) : null}
            className="data-entrega-picker"
            data-testid='deadLineControlEntrega'
          />
        </Col>
      </Row>
    );
  }
}

DeadlineControl.propTypes = {
  TransitTime: PropTypes.number.isRequired,
  Coleta: PropTypes.string,
  DataColetaDe: PropTypes.string.isRequired,
  DataEntregaDe: PropTypes.string.isRequired,
  OnChange: PropTypes.func.isRequired,
  Disabled: PropTypes.bool,
  ColetaDisabled: PropTypes.bool,
  CadeadoDisabled: PropTypes.bool,
  EntregaDisabled: PropTypes.bool,
  TituloColeta: PropTypes.string,
  TituloEntrega: PropTypes.string,
  Identificador: PropTypes.string,
  showSeparator: PropTypes.bool,
  noLockOption: PropTypes.bool,
  noMinDate: PropTypes.bool,
  noMaxDate: PropTypes.bool,
  days: PropTypes.string,
};

DeadlineControl.defaultProps = {
  Disabled: false,
  Coleta: "",
  EntregaDisabled: false,
  ColetaDisabled: false,
  CadeadoDisabled: false,
  TituloColeta: "Coleta",
  TituloEntrega: "Entrega",
  Identificador: "",
  showSeparator: true
};

export default DeadlineControl;
