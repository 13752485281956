import { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Checkbox } from '@hbsis.uikit/react'
import { Row, Col } from 'react-flexbox-grid'

import Session from 'utils/user-storage'

import StatusSpan from 'components/status-span'
import intl from 'react-intl-universal'

import StyledComposicaoList, { ColumnPortalCode, RowWrapper } from './styledComposicaoList'
import { formatNumber, formatValueByCurrency, formatWithoutLeadingZeros } from 'utils/format'
import Feature from 'components/feature';
import { TextSpan } from 'components/text-label'
import { isScheduleAgreementFlow, usesViewPriceColumnsOnLoadCompositionView } from 'utils/validations-general-settings'
import { getValueForCondition, getValueOrDefault } from 'utils/custom-functions'

class PedidosAprovados extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pedidos: props.pedidos,
      checkedAll: false,
      withBorder: true,
      withHover: true,
      borderColor: 'var(--color-action-hover)',
      borderColorHover: 'var(--color-action-default)'
    }
  }

  componentDidUpdate() {
    const pedidosReprojetados = this.props.pedidos
    if (pedidosReprojetados) {
      this.atualizarPrioridadeProjecao(pedidosReprojetados)
    }
  }

  atualizarPrioridadeProjecao = (pedidosReprojetados) => {
    const { pedidos } = this.state;
    const _pedidos = pedidos.map((pedido, index) => {
      const p = pedidosReprojetados.find(pedidoReprojetado => pedidoReprojetado.IdPedido === pedido.IdPedido);
      return {
        ...pedido,
        Prioridade: p ? p.Prioridade : -1
      }
    })

    this.setState({ pedidos: _pedidos })
  }

  onRowSelect = (row) => {
    let pedidos = this.state.pedidos.map((pedido, index) => {
      if (pedido.IdPedido === row.IdPedido) {
        pedido.Selecionado = !pedido.Selecionado
      }
      return pedido
    })
    this.handleSelectPedidos(pedidos)
  }

  checkedAll = () => {
    let pedidos = this.state.pedidos.map((pedido, index) => {
      pedido.Selecionado = !this.state.checkedAll
      return pedido
    })
    this.setState(prevState => ({
      checkedAll: !prevState.checkedAll
    }))
    this.handleSelectPedidos(pedidos)
  }

  handleSelectPedidos = (pedidos) => {
    const selecteds = pedidos.filter((pedido) => { return pedido.Selecionado }).map((v, i) => v.IdPedido)
    const checkedAll = selecteds.length == pedidos.length
    this.setState({
      pedidos,
      checkedAll
    }, () => { this.props.handleSelectRequest(selecteds) })
  }

  handleRedirect = (pedido) => {
    let url = '';

    if (this.getTypeUserCard() == 'AMBEV')
      url = `/stocksDetail/${pedido.IdFamiliaRotuladaCentro}/${pedido.IdPedido}/P`
    else
      url = `/ordersVisionDetail/${pedido.IdPedido}/P`;

    if (this.props.history) {
      this.props.history.push(url)
    } else if (this.props.redirectRoute) {
      this.props.redirectRoute(url)
    }
  }

  getTypeUserCard = () => {
    const user = Session.get()
    return user.TipoUsuario === 1 ? 'AMBEV' : 'SUPPLIER'
  }

  getColorName = (possuiComposicao, pedidoEmNegociacao) => {
    if (possuiComposicao)
      return "ebf6fe";
    if (pedidoEmNegociacao)
      return "e6e6e6";
    else
      return "ffffff";
  }

  renderTaskIndicator = (pedido) => {
    return (
      <ColumnPortalCode
        title={`${this.searchTaskType(pedido.PossuiComposicao, pedido.EmNegociacaoEmergencial)}`}
        onClick={() => this.handleRedirect(pedido)}
      >
        <span
          className={"column-codigo-link"}>
          {pedido.IdPedido}
        </span>
        {pedido.PossuiComposicao &&
          <span className="column-indicator">!</span>
        }
        {pedido.EmNegociacaoEmergencial &&
          <span className="column-indicator">?</span>
        }
      </ColumnPortalCode>
    )
  }

  searchTaskType = (possuiComposicao, emNegociacaoEmergencial) => {
    if (possuiComposicao) {
      return intl.get('loadComposition.message2');
    } else if (emNegociacaoEmergencial) {
      return intl.get('loadComposition.message3');
    } else {
      return intl.get('loadComposition.message1');
    }
  }

  render() {
    let { pedidos } = this.state
    let { enableSelected } = this.props
    let usuarioAdmin = Session.get().TipoUsuario === 1

    return (
      <StyledComposicaoList
        withBorder={this.state.withBorder}
        withHover={this.state.withHover}
        borderColor={this.state.borderColor}
        borderColorHover={this.state.borderColorHover}
      >
        <Row className="rowListHeader">
          <Col className="align-center column-checkbox">
            <Checkbox
              className="align-check"
              name="checkbox-all"
              text=" "
              checked={this.state.checkedAll}
              onChange={this.checkedAll}
              disabled={!enableSelected}
            />
          </Col>
          <Col className="align-center column-task" data-testid="po">{intl.get('commons.requestBuy')}</Col>
          <Col className="align-center column-item" data-testid="po-item">{intl.get('commons.item')}</Col>
          <Col className="align-center column-codigo" data-testid="portal-code">{intl.get('commons.portalCode')}</Col>
          <Col className="align-center column-material" data-testid="material">{intl.get('commons.material')}</Col>
          <Feature flags={usesViewPriceColumnsOnLoadCompositionView()}>
            <Col className="align-center column-liquidPrice" data-testid="net-price">{intl.get('commons.netPrice')}</Col>
            <Col className="align-center column-unitPrice" data-testid="unit-price">{intl.get('commons.unitPrice')}</Col>
          </Feature>
          <Feature flags={isScheduleAgreementFlow()}>
            <Col className="align-center column-sa" data-testid="scheduling-agreement">{intl.get('commons.schedulingAgreement')}</Col>
          </Feature>
          <Col className="align-center column-time" data-testid="delivery-time">{intl.get('commons.deliveryTime')}</Col>
          <Col className="align-center column-lote" data-testid="lot">{intl.get('commons.lot')}</Col>
          <Col className="align-center column-quantidade" data-testid="quantity">{intl.get('commons.amount')}</Col>
          <Col className="align-center column-um" data-testid="um">UM</Col>
          <Col className="align-center column-date" data-testid="pickup-date">{intl.get('commons.colPrevision')}</Col>
          <Col className="align-center column-date" data-testid="delivery-date">{intl.get('commons.deliveryPrevision')}</Col>
        </Row>
        <Row className="containerLista">
          <Col xs={12} md={12} lg={12} className="pt-0 pb-0">
            {(!pedidos || pedidos.length === 0) &&
              <Row className="rowList">
                <Col xs={12} md={12} lg={12} className="align-center">
                  <span className="no-results">{intl.get('commons.noRecordsFound')}</span>
                </Col>
              </Row>
            }
            {pedidos && !!pedidos.length && pedidos.map((pedido, i) => (
              <RowWrapper isDeleted={pedido.IsDeleted} data-testid={`deleted-${pedido.IdPedido}-${!!pedido.IsDeleted}`}>
                <Row
                  key={i}
                  className={getValueForCondition(pedido.Selecionado, "rowListSelected", "rowList")}
                  onClick={() => enableSelected ? this.onRowSelect(pedido) : null}
                  style={{
                    width: "100%",
                    cursor: 'pointer',
                    borderBottom: '1px dashed #98c1dc',
                    backgroundColor: `#${this.getColorName(pedido.PossuiComposicao, pedido.EmNegociacaoEmergencial)}`
                  }}
                >
                  <Col className="align-center column-checkbox">
                    <Checkbox
                      className="align-check"
                      name={`checkbox-${i}`}
                      text=" "
                      checked={pedido.Selecionado}
                      onClick={() => this.onRowSelect(pedido)}
                      disabled={!enableSelected}
                    />
                  </Col>
                  <Col className="align-center column-task" data-testid={`purchase-order-sap-${pedido.IdPedido}`}>{pedido.IdPedidoSap}</Col>
                  <Col className="align-center column-item" data-testid={`purchase-order-sap-item-${pedido.IdPedido}`}>{pedido.IdItemPedidoSap}</Col>
                  <Col className="align-center column-codigo" data-testid={`order-code-${pedido.IdPedido}`}>
                    <Feature validation={!(pedido.IsNew || !!pedido.IdSuggestion)}>
                      {this.renderTaskIndicator(pedido)}
                    </Feature>
                  </Col>
                  <Col className="align-center column-material" data-testid={`material-number-name-${pedido.IdPedido}`}>
                    <TextSpan>
                      {`${formatWithoutLeadingZeros(pedido.NumeroMaterial)} - ${pedido.Material}`}
                    </TextSpan>
                  </Col>
                  <Feature flags={usesViewPriceColumnsOnLoadCompositionView()}>
                    <Col className="align-center column-liquidPrice" data-testid={`liquid-price-${pedido.IdPedido}`}>{pedido.PrecoLiquido == null ? "-" : formatValueByCurrency(pedido.PrecoLiquido, pedido.Moeda)}</Col>
                    <Col className="align-center column-unitPrice" data-testid={`unit-price-${pedido.IdPedido}`}>{pedido.UnidadePreco == null ? "-" : formatNumber(pedido.UnidadePreco)}</Col>
                  </Feature>
                  <Feature flags={isScheduleAgreementFlow()}>
                    <Col className="align-center column-sa" data-testid={`scheduling-agreement-${pedido.IdPedido}`}>{getValueOrDefault(pedido.SchedulingAgreement, "-")}</Col>
                  </Feature>
                  <Col className="align-center column-time" data-testid={`delivery-time-${pedido.IdPedido}`}>{pedido.HoraEntrega ? moment(pedido.HoraEntrega, "HH:mm:ss").format("LT") : ''}</Col>
                  <Col className="align-center column-lote" data-testid={`lote-${pedido.IdPedido}`}>{pedido.Lote}</Col>
                  <Col className="align-center column-quantidade" data-testid={`quantity-${pedido.IdPedido}`}>
                    {usuarioAdmin &&
                      <StatusSpan
                        priority={pedido.Prioridade}
                        label={formatNumber(pedido.Quantidade)}
                      />
                    }
                    {!usuarioAdmin &&
                      formatNumber(pedido.Quantidade)
                    }
                  </Col>
                  <Col className="align-center column-um" data-testid={`um-${pedido.IdPedido}`}>
                    {usuarioAdmin &&
                      <StatusSpan
                        priority={pedido.Prioridade}
                        label={pedido.UnidadeMedida}
                      />
                    }
                    {!usuarioAdmin &&
                      pedido.UnidadeMedida
                    }
                  </Col>
                  <Col className="align-center column-date" data-testid={`delivery-date-${pedido.IdPedido}`}>
                    {usuarioAdmin &&
                      <StatusSpan
                        priority={pedido.Prioridade}
                        label={moment(pedido.DataColeta).format('L')}
                      />
                    }
                    {!usuarioAdmin &&
                      moment(pedido.DataColeta).format('L')
                    }
                  </Col>
                  <Col className="align-center column-date" data-testid={`colect-date-${pedido.IdPedido}`}>
                    {usuarioAdmin &&
                      <StatusSpan
                        priority={pedido.Prioridade}
                        label={moment(pedido.DataEntrega).format('L')}
                      />
                    }
                    {!usuarioAdmin &&
                      moment(pedido.DataEntrega).format('L')
                    }
                  </Col>
                </Row>
              </RowWrapper>
            ))
            }
          </Col>
        </Row>
      </StyledComposicaoList>
    )
  }
}

PedidosAprovados.propTypes = {
  QuantidadeTotal: PropTypes.number.isRequired,
  enableSelected: PropTypes.bool.isRequired,
  handleSelectRequest: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  pedidos: PropTypes.any.isRequired,
  redirectRoute: PropTypes.func.isRequired
}

PedidosAprovados.defaultProps = {
  enableSelected: true,
  QuantidadeTotal: 0,
}

export default PedidosAprovados
